import React, {Suspense, useEffect, useState, useContext} from "react"
import { BlocksContext } from "../context/blocksContext"
// import { Main_screen } from './main_screen';
import { Menu } from './menu';
import { Header } from './header';
import { Advantages } from './advantages';
import "../css/style.css";
import "../css/media.css";




export const Loader = () => {
    //const [loaded, setLoaded] = useState(2)

    const Popups = React.lazy(() => import("./popups"))
    const Loc = React.lazy(() => import("./loc"))
    const Plan_price = React.lazy(() => import("./plan_price"))
    const Get_select = React.lazy(() => import("./get_select"))
    const Galery = React.lazy(() => import("./galery"))
    const How_buy = React.lazy(() => import("./how_buy"))
    const Parking = React.lazy(() => import("./parking"))
    const About = React.lazy(() => import("./about"))
    const Hod_str = React.lazy(() => import("./hod_str"))
    const Contacts = React.lazy(() => import("./contacts"))
    const Footer = React.lazy(() => import("./footer"))

    const blocksImports = {
        // 'main_screen': <Main_screen />,
        'menu': <Menu />,
        'header': <Header />,
        'advantages': <Advantages />,
        'loc': <Loc />,
        'plan_price': <Plan_price />,
        'get_select': <Get_select />,
        'galery': <Galery />,
        'how_buy': <How_buy />,
        'parking': <Parking />,
        'about': <About />,
        //'hod_str': <Hod_str />,
        'contacts': <Contacts />,
        'footer': <Footer />,
    }

    
    const blocks = ["header", "menu", "advantages", "loc", "plan_price", "get_select", "galery", "how_buy",  "about", "contacts", "footer"] // "hod_str", "parking",

    const loaded = useContext(BlocksContext)

    const LoadBlock = (block) => {
        return  <Suspense  fallback={<div>Загрузка...</div>}>{blocksImports[block]}</Suspense>
    }

    const generateHtml = () =>{
        let toDraw = []
        for(let i=0;i<loaded.blocks; i++){
            toDraw.push(LoadBlock(blocks[i]))
        }
        return (
            <div className="blocks" data={loaded.menuClick?"true":""}>
                {toDraw.map((block) => block)}
                {loaded.popup?<Suspense  fallback={<div>Загрузка...</div>}><Popups /></Suspense>:""}
            </div>
        )
    }
    const  handleScroll = (event) => {

        if (loaded.blocks< blocks.length){
           
            loaded.setBlocks(20)
            loaded.setPopup(true)
           
        } else {
           window.removeEventListener('scroll', handleScroll, true);
        }
    }
    useEffect(()=> {
        if (loaded.blocks<blocks.length){
            window.addEventListener('scroll', handleScroll, true);
        }
    })
    return generateHtml()
    
}