import React, { useContext, useEffect, useState } from 'react'
import { BlocksContext } from "../context/blocksContext"
import { useSendForm } from '../hooks/send-from.hook'
import { TextField } from '@material-ui/core'
import InputMask from 'react-input-mask/lib/react-input-mask.development'

export const Menu = () => {
    const blocks = useContext(BlocksContext)
    const sendForm = useSendForm()
    const [open, setOpen] = useState(false)
    const [openAction, setOpenAction] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(false)



    const showPopup = (e) => {
        e.preventDefault()
        if (!blocks.popup) {
            blocks.setPopup(true)
            blocks.setBlocks(20)
            setTimeout(() => {
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display = "block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display = "none"
                });
                document.querySelector('.popup_menu').style.display = "block"
            }, 1400)
        } else {
            document.querySelector('body').classList.add('overflow')
            document.querySelector('.popup_rgba').style.display = "block"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display = "none"
            });
            document.querySelector('.popup_menu').style.display = "block"
        }

    }

    const handleScroll = (event) => {

        let lastPosition = parseInt(document.querySelector('html').getAttribute('lastPosition'))
        if (lastPosition != undefined) {
            if (lastPosition < window.scrollY + 100) {
                //console.log('down')
                document.querySelectorAll('section').forEach((section) => {
                    if (window.scrollY + (window.innerHeight / 2.5) > section.offsetTop && !section.classList.contains('showed')) {
                        section.classList.add('showed')
                    }
                })
            } else {
                //console.log('up')
            }
        }
        document.querySelector('html').setAttribute('lastPosition', window.scrollY)
    }

    const clickMenu = (e) => {
        e.preventDefault()
        let popup = e.currentTarget.getAttribute("href")
        if (blocks.blocks < 20) {
            blocks.setMenuClick(true)
            blocks.setBlocks(20)
            setTimeout(() => {
                window.scrollTo({
                    top: document.querySelector("." + popup).offsetTop,
                    behavior: "smooth"
                })
                setTimeout(() => {
                    blocks.setMenuClick(false)
                }, 500)


            }, 1200)

        } else {
            window.scrollTo({
                top: document.querySelector("." + popup).offsetTop,
                behavior: "smooth"
            })
        }

        setMobileMenu(false)
    }


    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);

    }, [])

    const closePopup = () => {
        console.log('close')
        try {
            document.querySelector('body').classList.remove('overflow')
            document.querySelector('.popup_rgba').style.display = "none"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display = "none"
            });
        } catch (e) {
            console.log(e)
        }
    }

    const showMobileMenu = () => {
        
    }

    return (
        <React.Fragment>
            <section className="menu">
                <div className="cw_main">
                    <logo><img src="images/logo.svg" /></logo>
                    <div className="menu_btn" onClick={showPopup} data="popup_menu"><span>Меню</span></div>
                    <div className="top_menu_list">
                        <a href="advantages" onClick={(e) => { e.preventDefault(); document.querySelector('.pu_action').classList.add('pu_action__open')   }}><span>Акции</span></a>
                        <a href="advantages" onClick={clickMenu}><span>Инфраструктура</span></a>
                        <a href="plan_price" onClick={clickMenu}><span>Планировки и цены</span></a>
                        <a href="how_buy" onClick={clickMenu}><span>Способы покупки</span></a>
                        <a href="contacts" onClick={clickMenu}><span>Контакты</span></a>
                    </div>

                    <div className="menu_right">
                        <a className="call_phone roistat-phone" href="tel:+79298436070"><l></l>8 (929) 843-60-70</a>
                    </div>
                </div>
            </section>
            <div className='pu_action'> {/* pu_action__open */}
                <div className='pu_action__close' onClick={() => { document.querySelector('.pu_action').classList.remove('pu_action__open') }}></div>
                <div className='pu_action__btn' onClick={() => { document.querySelector('.pu_action').classList.remove('pu_action__open') }}>
                    <img src='../images/pu_action_btn.png' />
                </div>
                <div className="ipoteka__info--wrap">
                    <img className='pu_action__img' src='../images/pu_action_img.jpg' />
                    <div className="ipoteka__info">
                        <div className="ipoteka__info--title">
                          Рассрочка от застройщика
                        </div>
                        <div className="ipoteka__info--subtitle">
                            Квартира от 2 000 руб/мес
                        </div>
                    </div>
                </div>
                <form className="pu_form">
                    <div className="form_in react_input_style input_name">
                        <TextField name="name" label="Ваше имя" className="pu_name" />
                    </div>
                    <div className="form_in react_input_style input_phone">
                        <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                            <TextField name="phone" label="Ваш телефон" className="pu_phone ym-record-keys" />
                        </InputMask>
                    </div>
                    <input type="hidden" class="text" value="Ипотека от 0,1%" />
                    <button onClick={(e) => { sendForm.sendForm(e) }} celtype="getAction" className="btn_main btn_style"><l></l><span>УЗНАТЬ ПОДРОБНЕЕ</span></button>
                </form>
            </div>
            
        </React.Fragment>
    )
}
